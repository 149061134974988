@import "../normalize.css";

@import "../components/all.css";
@import "../modifiers.css";

.background-main{
  width: 100%;
  height: 100%;
  z-index: -1;
  position: fixed;
  pointer-events: none;
  background-size: contain;
  background-image: url("../../assets/background2/fondo.svg");
  background-repeat: repeat-y;
  opacity: 0.5;
}
.background-main *{
}

body{
  height: max-content;
}


main{
  display: flex;
  flex-direction: column;
  place-items: center;
  place-content: start;
  width: 90%;
  min-height: 100vh;
  z-index: 1;
}
.basic-container{
  padding: 30px 50px;
}
@media screen and (min-width:1050px) {
  .background-main{
    background-size: cover;
  }
}
@media screen and (max-width:1075px) {
  main{
    min-height: unset;
  }
}

@media screen and (max-width:660px) {
  .nav__ul{
    display: none!important;
  }
  .nav__opener{
    display: flex;
  }
  .nav .buttons{
    display: none!important;
  }
}

.nav-side__section:first-child:after{
  display: none;
}

@media screen and (max-width:375px) {
  .basic-container{
    padding: 30px 10px 30px 10px!important;
  }
}